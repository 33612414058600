import './NavBar.css';

import React from 'react';

function NavBar() {
  return (
    <nav>
      <ul>
        <li><a href="/">Inicio</a></li>
        {/* <li><a href="#About">Nosotros</a></li> */}
        <li><a href="#Products">Productos</a></li>
        <li><a href="#Services">Servicios</a></li>
        <li><a href="#About">Nosotros</a></li>
        <li><a href="#Footer">Contacto</a></li>
      </ul>
    </nav>
  );
}

export default NavBar;
