import logo from './logo.svg';
import NavBar from './NavBar';
import Hero from './Hero';
import Footer from './Footer';
import Services from './Nosotros';
import ProductGrid from './ProductGrid';
import ServiceGrid from './ServiceGrid';
import Contacto from './Contacto';
import './App.css';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <body>
        <Hero />
        <ProductGrid/>
        <ServiceGrid/>
        <Services />
        <Contacto/>
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
