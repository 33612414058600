import React from 'react';
import './Footer.css';


function sendEmail() {
  window.location.href = 'mailto:info@arealighting.net';
}


function Footer() {
  return (
    <footer className="footer-container" id='Footer'>
      <div className="footer-left">
        <img src="/images/logo.png" alt="Area Lighting Logo" />
      </div>
      <div className="footer-center">
        <p>Calle 64A, Nro. 12-95 Urb. Maracaibo, Venezuela.</p>
        <p>(414) 638-9519 / info@arealighting.net</p>
        <p>Area Lighting Maracaibo C.A. ©2023</p>
      </div>
      <div className="footer-right">
      <ul style={{ display: 'flex', flexDirection: 'column' }}>
          <li><a href="#">Inicio</a></li>
          <li><a href="#Products">Productos</a></li>
          <li><a href="#Services">Servicios</a></li>
          <li><a href="#About">Nosotros</a></li>
          <li><a onClick={sendEmail}>Contacto</a></li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
