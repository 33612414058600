import React from 'react';
import './Nosotros.css';

function sendEmail() {
    window.location.href = 'mailto:info@arealighting.net';
  }

function nosotros() {
  return (
    <section className='nosotros-container' id="About">
      <h2>Sobre Nosotros</h2>
      <h3 >Somos una empresa, especializada en el suministro asesoria e instalacion de proyectos de iluminacion con mas de 20 años en el mercado.</h3>
      <p>Contamos con profesionales altamente capacitados para asesorar y brindar un servicio de óptima calidad. En nuestra gama de productos disponemos de artículos para la iluminación.</p>
      <ul>
        <li>Iluminacion Industrial</li>
        <li>Fines Commerciales</li>
        <li>Recidencial y Hogares</li>
        
      </ul>
      <button className="contact-btn"
      onClick={sendEmail}>Comunicate con Nosotros</button>
    </section>
  );
}

export default nosotros;
