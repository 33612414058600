import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <div className="hero-container">
      <h1>AREA LIGHTING</h1>
      <p>En Area Lighting contamos con una larga trayectoria en el sector de la iluminación.</p>
      <a href='#About'><button>Contactanos</button></a>
    </div>
  );
}

export default Hero;
