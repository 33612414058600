import React from 'react';
import './Contacto.css';
import fachada from './images/fachada.png';
import interior1 from './images/interior-1.jpeg';
import interior2 from './images/interior-2.png';


function Contacto() {
    return (
        <div className="image-gallery-container">
            <div className="main-image">
                <h1>Somos Tienda Física</h1>
                <img src={fachada} alt="Main Image" />
            </div>
            <div className="small-images">
                <div className="small-image">
                    <img src={interior1} alt="Small Image 1" />
                </div>
                <div className="small-image">
                    <img src={interior2} alt="Small Image 2" />
                </div>
            </div>
        </div>
  );
}

export default Contacto;
