import React from 'react';
import indirecta from './images/panel-luz-indirecta.jpeg';
import rielmagnetico from './images/riel-magnetico.png';
import bombilloled from './images/led-bombillo.jpeg';


import './ProductGrid.css';

function ProductGrid() {
  const products = [
    
    {
      name: 'Iluminación LED',
      image: bombilloled,
      description: 'This is the description for product 2',
    },
    {
      name: 'Rieles Magnéticos',
      image: rielmagnetico,
      description: 'This is the description for product 3',
    },
    {
      name: 'Páneles de Luz Indirecta',
      image: indirecta,
      description: 'This is the description for product 4',
    }
  ];

  return (
    <section className="product-grid" id='Products'>
      <h2 className='header-title'>PRODUCTOS</h2>
      <h3 className='header-title'>Suministros</h3> 
      <p className='header-description'>Suministro, Asesoría, Proyectos e Instalacion de artículos y sistemas de iluminacion.</p>
      <p className='header-description'>Ofrecemos luminarias para todas las áreas adaptándonos a las preferencias del cliente.</p>
      <div className="product-grid-container">
        {products.map((product) => (
          <div className="product-item" key={product.name}>
            <img src={product.image} alt={product.name} />
            <div className="product-details">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </div>
 
    </section>
  );
}

export default ProductGrid;

