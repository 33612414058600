import React from 'react';
import proyecto1 from './images/proyecto1.jpeg';
import proyecto2 from './images/proyecto2.jpeg';
import proyecto3 from './images/proyecto3.jpeg';
import paisajista from './images/paisajista.jpeg';


import './ServiceGrid.css';

function serviceGrid() {
  const services = [
    {
      name: 'Iluminación Paisajista',
      image: paisajista,
      description: 'La iluminacion paisajista es principalmente para darle un toque de elegancia a su jardin, casa, parque. Tenemos amplia experiencia en proyectos de iluminacion en exteriores en condiciones de sol y humedad que perduran en el tiempo. ',
    },
    {
      name: 'Páneles de Luz Indirecta',
      image: proyecto1,
      description: 'This is the description for service 4',
    },

    {
      name: 'Páneles de Luz Indirecta',
      image: proyecto2,
      description: 'This is the description for service 4',
    },

    {
      name: 'Páneles de Luz Indirecta',
      image: proyecto3,
      description: 'This is the description for service 4',
    }
  ];

  return (
    <section className="service-grid" id='Services'>
      <h2 className='header-title'>SERVICIOS</h2>
      <h3 className='header-title'>Asesoría  en proyectos e instalación</h3>
      <p className='header-description'>Le acompañamos en la realización de su proyecto ofreciéndoles los mejores serviceos y sus características así como su mejor ubicación para optimizar la iluminación en cada espacio.</p>

      <div className="service-grid-container">
        {services.map((service) => (
          <div className="service-item" key={service.name}>
            <img src={service.image} alt={service.name} />
            <div className="service-details">
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default serviceGrid;

